import { makeStyles, createStyles, Theme } from '@material-ui/core'


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonsContainer: {
            marginTop: 10,
            marginBottom: 20,
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 4
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
    })
)