import React, { lazy, Suspense, useContext } from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BrowserRouter as Router, Switch, Route, useRouteMatch } from 'react-router-dom'
import WaitSpinner from '../../common-components/wait-spinner/WaitSpinner'
import Header from '../../header/Header'
import SendEmailLink from '../../common-components/administrate-team/SendEmailLink'
import { AppContext } from '../../../state/context-initial-state/AppContext'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles'
import AllFavoritesRecommended from '../../common-components/all-favorites-recommended/AllFavoritesRecommended'


const CoachTeams = lazy(() => import('../teams/CoachTeams'))
const AdministrateTeam = lazy(() => import('../../common-components/administrate-team/AdministrateTeam'))
const TeamStatistics = lazy(() => import('../statistics/TeamStatistics'))
const RecommendMaterial = lazy(() => import('../recommend/RecommendMaterial'))
const ProgramsMainPage = lazy(() => import('../../player-app/programs/main/ProgramsMainPage'))
const GroupWithItems = lazy(() => import('../../player-app/group/GroupWithItems'))
const Item = lazy(() => import('../../player-app/item/main/Item'))
const BasicItem = lazy(() => import('../../player-app/basic/BasicItem'))
const BetMainPage = lazy(() => import('../../player-app/bet/BetMainPage'))
const ICoreMainPage = lazy(() => import('../../player-app/icore/ICoreMainPage'))
const WheelMaterials = lazy(() => import('../../player-app/wheel/wheel-materials/WheelMaterials'))
const ChatMainPage = lazy(() => import('../../player-app/chat/ChatMainPage'))
const Feedback = lazy(() => import('../../player-app/feedback/Feedback'))



type TeamNameMatchDataType = {
    teamName: string,
}
type UsernameMatchData = {
    username: string,
}
type GroupMatchData = {
    groupId: string,
}
type ItemMatchData = {
    itemId: string,
}
type BasicItemMatchData = {
    mainTitle: string,
}


const CoachApp: React.FC = () => {

    const { state } = useContext(AppContext)
    const classes = useStyles()
    
    const matchTeamNameAdministrate = useRouteMatch('/administrateTeam/:teamName')
    const teamNameAdministrate = matchTeamNameAdministrate ? (matchTeamNameAdministrate.params as TeamNameMatchDataType).teamName : undefined
    const matchUsername = useRouteMatch('/sendLink/:username')
    const username = matchUsername ? (matchUsername.params as UsernameMatchData).username : undefined
    const matchTeamNameStatistics = useRouteMatch('/activity/:teamName')
    const teamNameStatistics = matchTeamNameStatistics ? (matchTeamNameStatistics.params as TeamNameMatchDataType).teamName : undefined
    const matchTeamNameRecommend = useRouteMatch('/recommend/:teamName')
    const teamNameRecommend = matchTeamNameRecommend ? (matchTeamNameRecommend.params as TeamNameMatchDataType).teamName : undefined
    const matchTeamNameChat = useRouteMatch('/chat/:teamName')
    const teamNameChat = matchTeamNameChat ? (matchTeamNameChat.params as TeamNameMatchDataType).teamName : undefined

    const matchProgramGroup = useRouteMatch('/programGroups/:groupId')
    const programGroupId = matchProgramGroup ? (matchProgramGroup.params as GroupMatchData).groupId : undefined
    const matchWheelSectorGroup = useRouteMatch('/wheelGroups/:groupId')
    const wheelSectorGroupId = matchWheelSectorGroup ? (matchWheelSectorGroup.params as GroupMatchData).groupId : undefined
    const matchBetGroup = useRouteMatch('/betGroups/:groupId')
    const betGroupId = matchBetGroup ? (matchBetGroup.params as GroupMatchData).groupId : undefined
    const matchICoreGroup = useRouteMatch('/iCoreGroups/:groupId')
    const iCoreGroupId = matchICoreGroup ? (matchICoreGroup.params as GroupMatchData).groupId : undefined

    const matchItem = useRouteMatch('/items/:itemId')
    const itemId = matchItem ? (matchItem.params as ItemMatchData).itemId : undefined
    const matchBasicItem = useRouteMatch('/basicitems/:mainTitle')
    const mainTitle = matchBasicItem ? (matchBasicItem.params as BasicItemMatchData).mainTitle : undefined


    return (
        <Suspense fallback={<WaitSpinner/>}>
            <Header/>
            {state.coachSelectedTeam && state.coachIsRecommending &&
                <>
                    <div className={classes.outerContainer}>
                        <Typography className={classes.selectText}>Recommending material for team:</Typography>
                        <Typography variant='h6' className={classes.teamText}><strong>{state.coachSelectedTeam.toLocaleUpperCase()}</strong></Typography>
                    </div>
                    <AllFavoritesRecommended noFavorites={true}/>
                </>
            }
            <Switch>
                <Route path='/teams'>
                    <CoachTeams/>
                </Route>
                <Route path='/administrateTeam/:teamName'>
                    <AdministrateTeam teamName={teamNameAdministrate}/>
                </Route>
                <Route path='/sendLink/:username'>
                    <SendEmailLink username={username}/>
                </Route>
                <Route path='/activity/:teamName'>
                    <TeamStatistics teamName={teamNameStatistics}/>
                </Route>
                <Route path='/recommend/:teamName'>
                    <RecommendMaterial teamName={teamNameRecommend}/>
                </Route>
                <Route path='/chat/:teamName'>
                    <ChatMainPage teamName={teamNameChat}/>
                </Route>

                <Route path='/programs'>
                    <ProgramsMainPage/>
                </Route>
                <Route path='/programGroups/:groupId'>
                    <GroupWithItems groupId={programGroupId} type='programGroup'/>
                </Route>
                <Route path='/wheelmaterials'>
                    <WheelMaterials/>
                </Route>
                <Route path='/bet'>
                    <BetMainPage/>
                </Route>
                <Route path='/icore'>
                    <ICoreMainPage/>
                </Route>
                <Route path='/wheelGroups/:groupId'>
                    <GroupWithItems groupId={wheelSectorGroupId} type='wheelGroup'/>
                </Route>
                <Route path='/betGroups/:groupId'>
                    <GroupWithItems groupId={betGroupId} type='betGroup'/>
                </Route>
                <Route path='/iCoreGroups/:groupId'>
                    <GroupWithItems groupId={iCoreGroupId} type='iCoreGroup'/>
                </Route>
                <Route path='/items/:itemId'>
                    <Item itemId={itemId}/>
                </Route>
                <Route path='/basicitems/:mainTitle'>
                    <BasicItem mainTitle={mainTitle}/>
                </Route>

                <Route path='/home'>
                    <CoachTeams/>
                </Route>
                <Route path='/feedback'>
                    <Feedback/>
                </Route>
                <Route path='/'>
                    <CoachTeams/>
                </Route>
            </Switch>
        </Suspense>
    )
}


export default CoachApp



