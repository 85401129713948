import { LoggedInUserType } from '../login/LoggedInUserType'
import { NoteType } from '../note/NoteType'
import { UserRoleType } from '../user/UserRoleType'
import { ItemIsFavoriteChangeType } from '../item/ItemIsFavoriteChangeType'
import { SectorEvaluationChangedType } from '../wheel/SectorEvaluationChangedType'
import { MarkAsCompletedType } from '../completion/MarkAsCompletedType'
import { NewWheelEvaluationType } from '../wheel/NewWheelEvaluationType'
import { WheelHistoryType } from '../wheel/WheelHistoryType'
import { ProgramWithLimitedGroupInfoType } from '../program/ProgramWithLimitedGroupInfoType'
import { GroupDictionaryType } from '../group/GroupDictionaryType'
import { ItemDictionaryType } from '../item/ItemDictionaryType'
import { WheelSectorWithEvaluationsType } from '../wheel/WheelSectorWithEvaluationsType'
import { GroupWithCountInfoType } from '../group/GroupWithCountInfoType'
import { CoachAndAdminTeamType } from '../coach-and-admin-teams/CoachAndAdminTeamType'
import { ChangeItemRecommendationType } from '../recommendation/ChangeItemRecommendationType'
import { ChangeGroupRecommendationType } from '../recommendation/ChangeGroupRecommendationType'
import { MessageType } from '../message/MessageType'


export enum ActionTypes {
    'SET_LOGGED_IN_USER' = 'setLoggedInUser',
    'ADD_NEW_NOTE' = 'addNewNote',
    'SET_ALL_NOTES' = 'setAllNotes',
    'ADMIN_SET_TEAMS' = 'adminSetTeams',
    'ADMIN_ADD_NEW_TEAM' = 'adminAddNewTeam',
    'ADMIN_ADD_PERSON_TO_TEAM' = 'adminAddPersonToTeam',
    'COACH_ADD_PERSON_TO_TEAM' = 'coachAddPersonToTeam',
    'SET_PROGRAMS' = 'setPrograms',
    'SET_PROGRAM_GROUPS' = 'setProgramGroups',
    'SET_ITEMS' = 'setItems',
    'CHANGE_ITEM_IS_FAVORITE' = 'changeItemIsFavorite',
    'CHANGE_SHOW_ONLY_FAVORITES' = 'changeShowOnlyFavorites',
    'SET_WHEEL_SECTORS' = 'setWheelSectors',
    'SET_WHEEL_SECTOR_GROUPS' = 'setWheelSectorGroups',
    'CHANGE_SECTOR_EVALUATION_DATA' = 'changeSectorEvaluationData',
    'MARK_ITEM_AS_COMPLETED' = 'markItemAsCompleted',
    'LOGOUT' = 'logout',
    'SET_LIST_BET_GROUPS' = 'setListBetGroups',
    'SET_BET_GROUP_DATA' = 'setBetGroupData',
    'SET_I_CORE_GROUP_DATA' = 'setICoreGroupData',
    'SET_LIST_I_CORE_GROUPS' = 'setListICoreGroups',
    'SET_WHEEL_SECTOR_EVALUATIONS_HISTORY' = 'setWheelSectorEvaluationsHistory',
    'CHANGE_IS_RE_EVALUATING_WHEEL' = 'changeIsReEvaluatingWheel',
    'CHANGE_WHEEL_NEW_EVALUATI0N_VALUES' = 'changeWheelNewEvaluationValues',
    'SET_COACH_TEAMS' = 'setCoachTeams',
    'SET_COACH_SELECTED_TEAM' = 'setCoachSelectedTeam',
    'CHANGE_ITEM_RECOMMENDED_STATUS' = 'changeItemRecommendedStatus',
    'CHANGE_SHOW_ONLY_RECOMMENDED' = 'changeShowOnlyRecommended',
    'CHANGE_GROUP_RECOMMENDED_STATUS' = 'changeGroupRecommendedStatus',
    'SET_MESSAGES' = 'setMessages',
    'CHANGE_NOTE_IS_FAVORITE' = 'changeNoteIsFavorite',
    'SET_RESET_LINK_TEAM' = 'setResetLinkTeam',
    'CHANGE_COACH_IS_RECOMMENDING' = 'setCoachIsRecommending'
    
}


export type ActionType =
    | {
        type: ActionTypes.SET_LOGGED_IN_USER,
        data: LoggedInUserType | undefined
    }
    | {
    type: ActionTypes.ADD_NEW_NOTE,
    data: NoteType
    }
    | {
    type: ActionTypes.SET_ALL_NOTES,
    data: NoteType[]
    }
    | {
    type: ActionTypes.ADMIN_SET_TEAMS,
    data: CoachAndAdminTeamType[]
    }
    | {
    type: ActionTypes.ADMIN_ADD_NEW_TEAM,
    data: CoachAndAdminTeamType
    }
    | {
    type: ActionTypes.ADMIN_ADD_PERSON_TO_TEAM,
    data: { teamName: string, username: string, role: UserRoleType }
    }
    | {
    type: ActionTypes.COACH_ADD_PERSON_TO_TEAM,
    data: { teamName: string, username: string, role: UserRoleType }
    }
    | {
    type: ActionTypes.SET_PROGRAMS,
    data: ProgramWithLimitedGroupInfoType[]
    }
    | {
    type: ActionTypes.SET_PROGRAM_GROUPS,
    data: GroupDictionaryType
    }
    | {
    type: ActionTypes.SET_ITEMS,
    data: ItemDictionaryType
    }
    | {
    type: ActionTypes.CHANGE_ITEM_IS_FAVORITE,
    data: ItemIsFavoriteChangeType
    }
    | {
    type: ActionTypes.CHANGE_SHOW_ONLY_FAVORITES,
    data: boolean
    }
    | {
    type: ActionTypes.SET_WHEEL_SECTORS,
    data: WheelSectorWithEvaluationsType[]
    }
    | {
    type: ActionTypes.SET_WHEEL_SECTOR_GROUPS,
    data: GroupDictionaryType
    }
    | {
    type: ActionTypes.CHANGE_SECTOR_EVALUATION_DATA,
    data: SectorEvaluationChangedType
    }
    | {
    type: ActionTypes.MARK_ITEM_AS_COMPLETED,
    data: MarkAsCompletedType
    }
    | {
    type: ActionTypes.LOGOUT,
    data: null
    }
    | {
    type: ActionTypes.SET_BET_GROUP_DATA,
    data: GroupDictionaryType
    }
    | {
    type: ActionTypes.SET_LIST_BET_GROUPS,
    data: GroupWithCountInfoType[]
    }
    | {
    type: ActionTypes.SET_LIST_I_CORE_GROUPS,
    data: GroupWithCountInfoType[]
    }
    | {
    type: ActionTypes.SET_I_CORE_GROUP_DATA,
    data: GroupDictionaryType
    }
    | {
    type: ActionTypes.SET_WHEEL_SECTOR_EVALUATIONS_HISTORY,
    data: WheelHistoryType[]
    }
    | {
    type: ActionTypes.CHANGE_IS_RE_EVALUATING_WHEEL,
    data: boolean
    }
    | {
    type: ActionTypes.CHANGE_WHEEL_NEW_EVALUATI0N_VALUES,
    data: NewWheelEvaluationType | undefined
    }
    | {
    type: ActionTypes.SET_COACH_TEAMS,
    data: CoachAndAdminTeamType[]
    }
    | {
    type: ActionTypes.SET_COACH_SELECTED_TEAM,
    data: string | undefined
    }
    | {
    type: ActionTypes.CHANGE_ITEM_RECOMMENDED_STATUS,
    data: ChangeItemRecommendationType
    }
    | {
    type: ActionTypes.CHANGE_SHOW_ONLY_RECOMMENDED,
    data: boolean
    }
    | {
    type: ActionTypes.CHANGE_GROUP_RECOMMENDED_STATUS,
    data: ChangeGroupRecommendationType
    }
    | {
    type: ActionTypes.SET_MESSAGES,
    data: MessageType[]
    }
    | {
    type: ActionTypes.CHANGE_NOTE_IS_FAVORITE,
    data: NoteType
    }
    | {
    type: ActionTypes.SET_RESET_LINK_TEAM,
    data: string
    }
    | {
    type: ActionTypes.CHANGE_COACH_IS_RECOMMENDING,
    data: boolean
    }