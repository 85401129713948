import { BASICS_MUST_BE_PRESENT } from '../common/serverResponseErrorPhrases'
import { assertNever } from '../../common-functions/assertNever'
import { BasicsEnum } from '../../../types/basics/BasicsEnum'



export const parseBasic = (basic: unknown): BasicsEnum => {

    if (!basic || !isBasic(basic)) throw new Error(BASICS_MUST_BE_PRESENT)

    return basic
}


export const isBasic = (basic: unknown): basic is BasicsEnum => {

    if (!basic) throw new Error(BASICS_MUST_BE_PRESENT)

    switch (basic) {
    case BasicsEnum.PROGRAMS:
    case BasicsEnum.WHEEL:
    case BasicsEnum.NOTES:
    case BasicsEnum.STATISTICS:
    case BasicsEnum.ICORE:
    case BasicsEnum.BET:
    case BasicsEnum.MESSAGES:
    case BasicsEnum.APP_STORY:
    case BasicsEnum.IC_APPROACH_1:
    case BasicsEnum.IC_APPROACH_2:
    case BasicsEnum.HOW_TO_USE_VIDEO:
    case BasicsEnum.EXTRA:
        return true
    default:

        return assertNever(basic)
    }

}