import React, { useContext } from 'react'
import { AppContext } from '../../state/context-initial-state/AppContext'
import LoginPage from '../login-reset/login-page/LoginPage'
import { UserRoleType } from '../../types/user/UserRoleType'
import CoachApp from '../coach-app/main-page/CoachApp'
import PlayerApp from '../player-app/main-page/PlayerApp'
import AdminApp from '../admin-app/main-page/AdminApp'
import { assertNever } from '../../utils/common-functions/assertNever'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Player } from 'video-react'


const getAppForRole = (role: UserRoleType): JSX.Element => {
    switch (role) {
    case UserRoleType.ADMIN:
        return <AdminApp/>
    case UserRoleType.COACH:
        return <CoachApp/>
    case UserRoleType.PLAYER:
        return <PlayerApp/>
    default:
        return assertNever(role)
    }
}


const MentalCoachingApp: React.FC = () => {


    const { state } = useContext(AppContext)
    const loggedInUser = state.loggedInUserData


    if (loggedInUser === undefined) return (
        <Router>
            <Switch>
                <Route path='/'>
                    <LoginPage/>
                </Route>
            </Switch>
        </Router>
    )

    return (
        <Router>
            {getAppForRole(loggedInUser.role)}
        </Router>
    )

}


export default MentalCoachingApp

