
import { useContext, useState } from 'react'
import { sendResetLink } from './service'
import { AppContext } from '../../../state/context-initial-state/AppContext'
import { ERROR_SENDING_RESET_LINK, SUCCESS_SENDING_RESET_LINK } from '../../../utils/common-constants/constantValues'



type UseSendEmail = {
    responseMessage: string,
    clearResponseMessage: () => void,
    sendEmail: (username: string, email: string) => void,
}



export const useSendEmail = (): UseSendEmail => {

    const [responseMessage, setResponseMessage] = useState('')
    const { state } = useContext(AppContext)


    const sendEmail = async (username: string, email: string) => {
        try {
            const token = state.loggedInUserData?.token
            if (token) {
                await sendResetLink(token, username, email)
                setResponseMessage(SUCCESS_SENDING_RESET_LINK)
            }
        } catch (error) {
            console.log(error)
            setResponseMessage(ERROR_SENDING_RESET_LINK)
        }
    }



    const clearResponseMessage = () => {
        setResponseMessage('')
    }

    return {
        responseMessage,
        clearResponseMessage,
        sendEmail,
    }

}
