import { WheelSectorWithEvaluationsType } from '../../types/wheel/WheelSectorWithEvaluationsType'
import { SectorEvaluationChangedType } from '../../types/wheel/SectorEvaluationChangedType'

export const getWheelSectorsAfterEvaluation = (sectors: WheelSectorWithEvaluationsType[] | undefined, sectorEvaluation: SectorEvaluationChangedType): WheelSectorWithEvaluationsType[] | undefined => {
    if (sectors === undefined) return sectors
    return sectors.map(sector => {
        if (sector.id === sectorEvaluation.wheelSectorId) {
            return { ...sector, value: sectorEvaluation.value, subValue1: sectorEvaluation.subValue1, subValue2: sectorEvaluation.subValue2 }
        } else {
            return sector
        }
    })
}
