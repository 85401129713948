import { LoggedInUserType } from '../../../types/login/LoggedInUserType'
import { parseUsername, parseToken, parseRole } from '../common/generalServerResponseValidations'
import { parseBasics } from './parseBasics'
import { LoginUserDataInServerResponseType } from '../../../types/login/LoginUserDataInServerResponseType'



export const parseLoginUserData = (responseData: unknown): LoggedInUserType => {
    const response = responseData as LoginUserDataInServerResponseType


    return {
        username: parseUsername(response.username),
        token: parseToken(response.token),
        role: parseRole(response.role),
        basics: parseBasics(response.basics),
    }
}

