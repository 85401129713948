import { makeStyles, createStyles } from '@material-ui/core'


export const useStyles = makeStyles((theme) =>
    createStyles({
        teamText: {
            color: theme.palette.primary.main,
            textAlign: 'center',
        },
        selectText: {
            color: theme.palette.primary.main,
            textAlign: 'center',
            marginBottom: -5
        },
        container: {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 6,
            width: '95%',
            padding: 5,
        },
        outerContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 10,
            width: '100%',
        }
    })
)