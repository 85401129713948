import { GroupDictionaryType } from '../../../types/group/GroupDictionaryType'
import { ChangeGroupRecommendationType } from '../../../types/recommendation/ChangeGroupRecommendationType'
import { ProgramWithLimitedGroupInfoType } from '../../../types/program/ProgramWithLimitedGroupInfoType'
import { WheelSectorWithEvaluationsType } from '../../../types/wheel/WheelSectorWithEvaluationsType'
import { GroupWithCountInfoType } from '../../../types/group/GroupWithCountInfoType'




export const getGroupsAfterGroupIsRecommendedChanged = (groups: GroupDictionaryType, changeData: ChangeGroupRecommendationType): GroupDictionaryType => {
    const updatedGroups: GroupDictionaryType = {}
    Object.values(groups).forEach(group => {
        if (group.groupId === changeData.groupId) {
            const groupItems = group.groupItems.map(item => {
                return { ...item, groupRecommended: changeData.recommended }
            })
            updatedGroups[group.groupId] = { ...group, groupItems: groupItems, recommended: changeData.recommended }
        } else {
            updatedGroups[group.groupId] = { ...group }
        }
    })
    return updatedGroups
}


export const getProgramsAfterGroupIsRecommendedChanged = (programs: ProgramWithLimitedGroupInfoType[] | undefined, groups: GroupDictionaryType, changeData: ChangeGroupRecommendationType): ProgramWithLimitedGroupInfoType[] | undefined => {
    const updatedGroups = getGroupsAfterGroupIsRecommendedChanged(groups, changeData)
    if (programs === undefined) return programs
    return programs.map(program => {
        const updatedProgramGroups = program.groups.map(group => {
            if (group.groupId === changeData.groupId) {
                let recommendedCount = 0
                if (updatedGroups[group.groupId].recommended) {
                    recommendedCount = updatedGroups[group.groupId].groupItems.length
                } else {
                    recommendedCount = updatedGroups[group.groupId].groupItems.reduce((count, currentItem) => {
                        if (currentItem.recommended) return count + 1
                        return count
                    }, 0)
                }
                return { ...group, groupRecommendedItemsCount: recommendedCount, recommended: updatedGroups[group.groupId].recommended }
            } else {
                return group
            }

        })
        const programHasNoRecommendations = updatedProgramGroups.every(group => {
            if (group.recommended) return false
            return group.groupRecommendedItemsCount === 0
        })
        return { ...program, hasRecommendations: !programHasNoRecommendations, groups: updatedProgramGroups  }
    })
}

export const getWheelSectorsAfterGroupIsRecommendedChanged = (wheelSectors: WheelSectorWithEvaluationsType[] | undefined, changeData: ChangeGroupRecommendationType): WheelSectorWithEvaluationsType[] | undefined => {
    if (wheelSectors === undefined) return wheelSectors
    return wheelSectors.map(sector => {
        if (sector.id === changeData.groupId) {
            let updatedRecommendationsCount = 0
            const updatedItems = sector.items.map(item => {
                if (item.recommended) updatedRecommendationsCount += 1
                return { ...item, groupRecommended: changeData.recommended }
            })
            if (changeData.recommended) {
                updatedRecommendationsCount = sector.items.length
            }
            return { ...sector, items: updatedItems, groupRecommendedItemsCount: updatedRecommendationsCount, recommended: changeData.recommended }
        } else {
            return sector
        }
    })
}

export const getBetOrICoreGroupListAfterGroupIsRecommendedChanged = (list: GroupWithCountInfoType[] | undefined, groups: GroupDictionaryType,  changeData: ChangeGroupRecommendationType): GroupWithCountInfoType[] | undefined => {
    if (list === undefined) return list
    const updatedGroups = getGroupsAfterGroupIsRecommendedChanged(groups, changeData)
    return  list.map(group => {
        let recommendationsCount = updatedGroups[group.groupId].groupItems.reduce((count, currentItem) => {
            if (currentItem.recommended) return count + 1
            return count
        }, 0)
        if (group.groupId === changeData.groupId) recommendationsCount = group.groupItemsCount
        return { ...group, groupRecommendedItemsCount: recommendationsCount }
    })
}