import { WheelNewEvaluationsDictionaryType } from '../../types/wheel/WheelNewEvaluationsDictionaryType'
import { NewWheelEvaluationType } from '../../types/wheel/NewWheelEvaluationType'

export const changeNewWheelEvaluationValues = (
    wheelNewEvaluations: WheelNewEvaluationsDictionaryType | undefined,
    newValues: NewWheelEvaluationType | undefined
): WheelNewEvaluationsDictionaryType | undefined => {
    if (newValues === undefined) return undefined
    const updatedEvaluations = { ...wheelNewEvaluations }
    updatedEvaluations[newValues.wheelSectorId] = newValues
    return updatedEvaluations

}