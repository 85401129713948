
// LOGIN USER:
export const LOGIN_ERROR = 'Login user data error'
export const USERNAME_MUST_BE_PRESENT_AND_STRING = `${LOGIN_ERROR}: Username must be present and of data type string.`
export const TOKEN_MUST_BE_PRESENT_AND_STRING =  `${LOGIN_ERROR}: Token must be present and of data type string.`
export const ROLE_MUST_BE_PRESENT_AND_ROLE =  `${LOGIN_ERROR}: Role must be present and of data type user role.`
export const BASICS_MUST_BE_PRESENT = `${LOGIN_ERROR}: Basics must be present.`


// NEW NOTE / GET NOTES:
export const ID_REQUIRED_AND_NUMBER = 'id is required and must be of data type number.'
export const ID_NON_REQUIRED_BUT_NUMBER = 'id is not required but when present must be of data type null.'
export const DATE_IS_REQUIRED_AND_MUST_BE_STRING = 'Date is required and must be of data type string.'
export const NOTE_MUST_HAVE_CONTENT = 'Note must have content, at least either text, audio or photo.'
export const NOTE_FAVORITE_STATUS_MISSING = 'Note must have is favorite status.'


// ITEM:
export const ITEM_MUST_HAVE_CONTENT = 'Item must have content, either text, audio or video.'
export const RECOMMENDATION_MUST_BE_BOOLEAN = 'Item recommendation data must be boolean.'


export const SOME_TEXT_ERROR = 'text is required and must be of data type string.'
export const GROUP_ITEMS_MUST_BE_ARRAY = 'Group item must be of type array.'


// WHEEL:
export const SECTOR_MUST_CONTAIN_VALUE_OR_SUB_VALUE_NUMBERS = 'Wheel sector must contain either a value or sub values 1 and 2.'
export const OPTIONAL_NUMBER_ERROR = ' is optional, but if present must be of data type number.'


// GROUP:
export const GROUP_TYPE = 'Group must be of some enumerated type.'
export const GROUP_RECOMMENDATION = 'Group recommendation data is needed and must be of type boolean.'


// COACH:
export const PERSONS_LIST_ERROR = 'Persons list must be an array of strings or an empty array.'


