import React from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { useStyles } from './styles'


type ButtonGroupSwitchTripleProps = {
    buttonSwitchValues: string[],
    displayValue: string,
    changeShowLeftFunction: () => void,
    changeShowRightFunction: () => void,
    changeShowMiddleFunction: () => void,
    showMiddle?: boolean,
}




const ButtonGroupSwitchTriple: React.FC<ButtonGroupSwitchTripleProps> = ({ 
    buttonSwitchValues, 
    displayValue, 
    changeShowLeftFunction,
    changeShowRightFunction,
    changeShowMiddleFunction,
    showMiddle
}) => {

    const classes = useStyles()


    return (
        <div className={classes.container} >
            <div className={classes.buttonsContainer} >
                <ButtonGroup variant='text' color='primary'>
                    <Button onClick={() => changeShowLeftFunction()} variant={displayValue === buttonSwitchValues[0] ? 'contained' : 'text'}>
                        <Typography>
                            <strong>{buttonSwitchValues[0]}</strong>
                        </Typography>
                    </Button>
                    {showMiddle &&
                        <Button onClick={() => changeShowMiddleFunction()} variant={displayValue === buttonSwitchValues[1] ? 'contained' : 'text'}>
                            <Typography>
                                <strong>{buttonSwitchValues[1]}</strong>
                            </Typography>
                        </Button>
                    }
                    <Button onClick={() => changeShowRightFunction()}  variant={displayValue === buttonSwitchValues[2] ? 'contained' : 'text'} >
                        <Typography>
                            <strong>{buttonSwitchValues[2]}</strong>
                        </Typography>
                    </Button>
                </ButtonGroup>
            </div>
        </div>


    )
}


export default ButtonGroupSwitchTriple