import { AppStateType } from '../../types/app-state/AppStateType'




export const initialAppState: AppStateType = {
    loggedInUserData: undefined,
    notes: [],
    adminTeams: [],
    programs: undefined,
    programGroups: {},
    items: {},
    showFavoritesOnly: false,
    wheelSectors: undefined,
    wheelSectorGroups: {},
    wheelEvaluationsHistory: undefined,
    wheelNewEvaluations: undefined,
    isReEvaluatingWheel: false,
    betGroups: {},
    betList: undefined,
    iCoreGroups: {},
    iCoreList: undefined,
    coachTeams: undefined,
    coachSelectedTeam: undefined,
    showRecommendedOnly: false,
    messages: undefined,
    resetLinkTeam: undefined,
    coachIsRecommending: false,
}