import {
    parseNonRequiredId,
    parseOptionalText,
    parseRequiredId,
    parseText
} from '../common/generalServerResponseValidations'
import { parseBasic } from './parseBasic'
import { BasicInServerResponseType } from '../../../types/basics/BasicInServerResponseType'
import { BasicsDictionaryType } from '../../../types/basics/BasicsDictionaryType'



export const parseBasics = (basics: BasicInServerResponseType[] | undefined): BasicsDictionaryType | undefined => {

    if (basics === undefined) return undefined
    const basicsDictionary: BasicsDictionaryType = {}
    basics.forEach(basic => {
        const title = parseBasic(basic.title)

        basicsDictionary[title.toString()] = {
            title: parseBasic(basic.title),
            itemId: parseRequiredId(basic.item_id, 'Basic item'),
            name: parseText(basic.name, 'Basic item name'),
            textId: parseNonRequiredId(basic.text_id, 'Basic item text id'),
            audioName: parseOptionalText(basic.audio_name, 'Basic audio name'),
            videoName: parseOptionalText(basic.video_name, 'Basic video name')
        }
    })

    return basicsDictionary
}