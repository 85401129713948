import { makeStyles, createStyles, Theme } from '@material-ui/core'


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        formInputField: {
            marginTop: 15,
        },
        formTitle: {
            marginTop: 50,
            color: theme.palette.primary.main,
        },
        button: {
            marginTop: 40,
        }
    })
)

