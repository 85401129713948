import React from 'react'
import Header from '../../header/Header'
import LoginForm from '../login-form/LoginForm'

const LoginPage: React.FC = () => {

    return (
        <div>
            <Header/>

            <LoginForm/>


        </div>
    )
}


export default LoginPage

