export enum BasicsEnum {
    WHEEL = 'WHEEL',
    PROGRAMS = 'PROGRAMS',
    BET = 'BET',
    ICORE = 'ICORE',
    NOTES = 'NOTES',
    STATISTICS = 'STATISTICS',
    MESSAGES = 'MESSAGES',
    APP_STORY = 'APP STORY',
    IC_APPROACH_1 = 'IC APPROACH 1',
    IC_APPROACH_2 = 'IC APPROACH 2',
    HOW_TO_USE_VIDEO = 'HOW TO USE THIS APP',
    EXTRA = 'EXTRA',
}