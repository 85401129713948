import { ItemIsFavoriteChangeType } from '../../../types/item/ItemIsFavoriteChangeType'
import { WheelSectorWithEvaluationsType } from '../../../types/wheel/WheelSectorWithEvaluationsType'
import { ItemDictionaryType } from '../../../types/item/ItemDictionaryType'
import { GroupDictionaryType } from '../../../types/group/GroupDictionaryType'
import { ProgramWithLimitedGroupInfoType } from '../../../types/program/ProgramWithLimitedGroupInfoType'
import { GroupWithCountInfoType } from '../../../types/group/GroupWithCountInfoType'



export const getItemsAfterItemIsFavoriteChanged = (items: ItemDictionaryType, changeData: ItemIsFavoriteChangeType): ItemDictionaryType => {
    const itemToBeUpdated = items[changeData.itemId]
    const updatedItem = { ...itemToBeUpdated, itemFavoriteId: changeData.favoriteId }
    items[changeData.itemId] = updatedItem
    return { ...items }
}

export const getGroupsAfterItemIsFavoriteChanged = (groups: GroupDictionaryType, changeData: ItemIsFavoriteChangeType): GroupDictionaryType => {
    const updatedGroups: GroupDictionaryType = {}
    Object.values(groups).forEach(group => {
        const groupItems = group.groupItems.map(item => {
            if (item.itemId === changeData.itemId) return { ...item, itemFavoriteId: changeData.favoriteId }
            return  { ...item }
        })
        updatedGroups[group.groupId] = { ...group, groupItems: groupItems }
    })
    return updatedGroups
}

export const getProgramsAfterItemIsFavoriteChanged = (programs: ProgramWithLimitedGroupInfoType[] | undefined, groups: GroupDictionaryType, changeData: ItemIsFavoriteChangeType): ProgramWithLimitedGroupInfoType[] | undefined => {
    const updatedGroups = getGroupsAfterItemIsFavoriteChanged(groups, changeData)
    if (programs === undefined) return programs
    return programs.map(program => {
        const updatedProgramGroups = program.groups.map(group => {
            const favoritesCount = updatedGroups[group.groupId].groupItems.reduce((count, currentItem) => {
                if (currentItem.itemFavoriteId !== null) return count + 1
                return count
            }, 0)
            return { ...group, favoritesCount: favoritesCount }
        })
        const programHasNoFavorites = updatedProgramGroups.every(group => {
            return group.favoritesCount === 0
        })
        return { ...program, hasFavorites: !programHasNoFavorites  }
    })
}

export const getWheelSectorsAfterItemIsFavoriteChanged = (wheelSectors: WheelSectorWithEvaluationsType[] | undefined, changeData: ItemIsFavoriteChangeType): WheelSectorWithEvaluationsType[] | undefined => {
    if (wheelSectors === undefined) return wheelSectors
    return wheelSectors.map(sector => {
        let updatedFavoritesCount = 0
        const items = sector.items.map(item => {
            if (item.itemId === changeData.itemId) {
                if (changeData.favoriteId !== null) updatedFavoritesCount += 1
                return { ...item, itemFavoriteId: changeData.favoriteId }
            }
            else {
                if (item.itemFavoriteId !== null) updatedFavoritesCount += 1
                return item
            }
        })
        return { ...sector, items: items, groupFavoriteItemsCount: updatedFavoritesCount }
    })
}

export const getBetOrICoreGroupListAfterItemIsFavoriteChanged = (list: GroupWithCountInfoType[] | undefined, groups: GroupDictionaryType,  changeData: ItemIsFavoriteChangeType): GroupWithCountInfoType[] | undefined => {
    if (list === undefined) return list
    const updatedGroups = getGroupsAfterItemIsFavoriteChanged(groups, changeData)
    return  list.map(group => {
        const favoritesCount = updatedGroups[group.groupId].groupItems.reduce((count, currentItem) => {
            if (currentItem.itemFavoriteId !== null) return count + 1
            return count
        }, 0)
        return { ...group, favoritesCount: favoritesCount }
    })
}









