import { HeaderWithAuthorizationType } from '../../types/header/HeaderWithAuthorizationType'


export const getHeaderWithAuthorization = (token: string): HeaderWithAuthorizationType => {

    return {
        headers: {
            'authorization': token
        }
    }
}