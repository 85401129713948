import React from 'react'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import { useStyles } from './styles'


type FormInputFieldProps = {
    fieldName: string,
    label: string,
    type: string
}


const FormInputField: React.FC<FormInputFieldProps> = ({ fieldName, label , type }) => {
    
    const classes = useStyles()
    
    return (
        <Field
            id={label}
            type={type}
            component={TextField}
            name={fieldName}
            label={label}
            className={classes.formInputField}
        />
    )
}

export default FormInputField