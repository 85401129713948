import axios from 'axios'


const baseUrl = process.env.REACT_APP_SERVER_URL

export const loginUser = async (username: string, password: string): Promise<unknown> => {
    const response = await axios.post(`${baseUrl}/login`, { username: username, password: password })
    return response.data
}




