import { UserRoleType } from '../../../types/user/UserRoleType'
import {
    USERNAME_MUST_BE_PRESENT_AND_STRING, TOKEN_MUST_BE_PRESENT_AND_STRING, ROLE_MUST_BE_PRESENT_AND_ROLE,
    ID_REQUIRED_AND_NUMBER, ID_NON_REQUIRED_BUT_NUMBER, DATE_IS_REQUIRED_AND_MUST_BE_STRING, NOTE_MUST_HAVE_CONTENT,
    ITEM_MUST_HAVE_CONTENT, SOME_TEXT_ERROR,
    GROUP_ITEMS_MUST_BE_ARRAY, OPTIONAL_NUMBER_ERROR, NOTE_FAVORITE_STATUS_MISSING, GROUP_RECOMMENDATION,

} from './serverResponseErrorPhrases'
import { assertNever } from '../../common-functions/assertNever'
import { NoteType } from '../../../types/note/NoteType'
import { NoteInServerResponseType } from '../../../types/note/NoteInServerResponseType'
import { ItemType } from '../../../types/item/ItemType'
import { ItemInServerResponseType } from '../../../types/item/ItemInServerResponseType'


export const isString = (text: unknown): text is string => {
    return typeof text === 'string' || text instanceof String
}

export const isNumber = (number: unknown): number is number => {
    return typeof number === 'number' || number instanceof Number
}

export const isBoolean = (value: unknown): value is boolean => {
    return value === true || value === false
}



export const parseUsername = (username: unknown): string => {
    if (!username || !isString(username)) throw new Error(USERNAME_MUST_BE_PRESENT_AND_STRING)
    return username
}

export const parseToken = (token: unknown): string => {
    if (!token || !isString(token)) throw new Error(TOKEN_MUST_BE_PRESENT_AND_STRING)
    return token
}

export const parseRole = (role: unknown): UserRoleType => {
    if (!role || !isUserRole(role)) throw new Error(ROLE_MUST_BE_PRESENT_AND_ROLE)
    return role
}


export const isUserRole = (role: unknown): role is UserRoleType => {
    if (!role) throw new Error(ROLE_MUST_BE_PRESENT_AND_ROLE)
    switch (role) {
    case UserRoleType.ADMIN: case UserRoleType.COACH: case UserRoleType.PLAYER:
        return true
    default:
        return assertNever(role)
    }
}

export const parseRequiredId = (targetId: unknown, target: string): number => {
    if (!targetId || !isNumber(targetId)) throw new Error(`${target} ${ID_REQUIRED_AND_NUMBER}`)
    return targetId
}

export const parseNonRequiredId = (targetId: unknown, target: string): number | null => {
    if (!targetId) return null
    if (!isNumber(targetId)) throw new Error(`${target} ${ID_NON_REQUIRED_BUT_NUMBER}`)
    return targetId
}

export const parseDate = (date: unknown): Date => {
    if (!date || !isString(date)) throw new Error(DATE_IS_REQUIRED_AND_MUST_BE_STRING)
    const dateOfNote = new Date(date)
    return dateOfNote
}
export const parseNameWithNumber = (text: unknown, title: string): string => {
    if (!text || !isString(text)) throw new Error(`${title} ${SOME_TEXT_ERROR}`)
    return text.replace(/\d+[*]/, '')
}
export const parseText = (text: unknown, title: string): string => {
    if (!text || !isString(text)) throw new Error(`${title} ${SOME_TEXT_ERROR}`)
    return text
}
export const parseOptionalText = (text: unknown, title: string): string | null => {
    if (!text) return null
    if (!isString(text)) throw new Error(`${title} ${SOME_TEXT_ERROR}`)
    return text
}
export const parseOptionalIntegerValue = (number: unknown, title: string): number | null => {
    if (!number) return null
    if (!isNumber(number)) throw new Error(`${title} ${OPTIONAL_NUMBER_ERROR}`)
    return number
}

export const parseIsFavoriteNote = (is_favorite: unknown): boolean => {
    if (!isBoolean(is_favorite)) throw new Error(NOTE_FAVORITE_STATUS_MISSING)
    return is_favorite
}


export const parseNote = (noteInResponse: unknown): NoteType => {
    const note = noteInResponse as NoteInServerResponseType

    if (note.text_id === null && note.audio_name === null && note.photo_name === null) throw new Error(NOTE_MUST_HAVE_CONTENT)
    return {
        noteId: parseRequiredId(note.id, 'Note'),
        title: parseText(note.title, 'Note title'),
        textId: parseNonRequiredId(note.text_id, 'Text'),
        audioName: parseOptionalText(note.audio_name, 'Note audio name'),
        photoName: parseOptionalText(note.photo_name, 'Note photo name'),
        date: parseDate(note.date),
        isFavorite: parseIsFavoriteNote(note.is_favorite),
    }
}


export const parseItem = (item: ItemInServerResponseType): ItemType => {
    if (!item.text_id && !item.audio_name && !item.video_name) throw new Error(ITEM_MUST_HAVE_CONTENT)
    return {
        id: parseRequiredId(item.id, 'Item'),
        name: parseText(item.name, 'Program name'),
        favoriteId: parseNonRequiredId(item.favorite_id, 'Favorite'),
        textId: parseNonRequiredId(item.text_id, 'Text'),
        audioName: parseOptionalText(item.audio_name, 'Audio name'),
        videoName: parseOptionalText(item.video_name, 'Video name')
    }
}

export const parseContentType = (item: ItemInServerResponseType): 'text' | 'audio' | 'video' => {
    if (item.text_id === null && item.audio_name === null && item.video_name === null) throw new Error(ITEM_MUST_HAVE_CONTENT)
    return item.text_id !== null ? 'text' : item.audio_name !== null ? 'audio' : 'video'
}



export const getItemsCountInProgramGroup = (items: ItemInServerResponseType[]): number => {
    if (!Array.isArray(items)) throw new Error(GROUP_ITEMS_MUST_BE_ARRAY)
    return items.length
}
export const getFavoriteItemsInGroupCount = (items: ItemInServerResponseType[]): number => {
    return items.reduce((accumulator, currentItem) => {
        if (currentItem.favorite_id !== null) return accumulator + 1
        else return accumulator
    }, 0)
}

export const parseItems = (itemsInResponse: unknown): ItemType[] => {
    const items = itemsInResponse as ItemInServerResponseType[]
    return items.map(item => parseItem(item))
}


export const getRecommendedItemsInGroupCount = (groupIsRecommended: boolean | null, items: ItemInServerResponseType[]): number => {
    if (groupIsRecommended) return items.length
    return items.reduce((accumulator, currentItem) => {
        if (currentItem.recommended === true) return accumulator + 1
        else return accumulator
    }, 0)
}

export const parseGroupRecommendation = (recommended: unknown): boolean => {
    if (!(isBoolean(recommended))) throw new Error(GROUP_RECOMMENDATION)
    return recommended
}
