import { WheelSectorWithEvaluationsType } from '../../../types/wheel/WheelSectorWithEvaluationsType'
import { ItemDictionaryType } from '../../../types/item/ItemDictionaryType'
import { GroupDictionaryType } from '../../../types/group/GroupDictionaryType'
import { ProgramWithLimitedGroupInfoType } from '../../../types/program/ProgramWithLimitedGroupInfoType'
import { GroupWithCountInfoType } from '../../../types/group/GroupWithCountInfoType'
import { ChangeItemRecommendationType } from '../../../types/recommendation/ChangeItemRecommendationType'



export const getItemsAfterItemIsRecommendedChanged = (items: ItemDictionaryType, changeData: ChangeItemRecommendationType): ItemDictionaryType => {
    const itemToBeUpdated = items[changeData.itemId]
    const updatedItem = { ...itemToBeUpdated, recommended: changeData.recommended }
    items[changeData.itemId] = updatedItem
    return { ...items }
}

export const getGroupsAfterItemIsRecommendedChanged = (groups: GroupDictionaryType, changeData: ChangeItemRecommendationType): GroupDictionaryType => {
    const updatedGroups: GroupDictionaryType = {}
    Object.values(groups).forEach(group => {
        const groupItems = group.groupItems.map(item => {
            if (item.itemId === changeData.itemId) return { ...item, recommended: changeData.recommended }
            return  { ...item }
        })
        updatedGroups[group.groupId] = { ...group, groupItems: groupItems }
    })
    return updatedGroups
}

export const getProgramsAfterItemIsRecommendedChanged = (programs: ProgramWithLimitedGroupInfoType[] | undefined, groups: GroupDictionaryType, changeData: ChangeItemRecommendationType): ProgramWithLimitedGroupInfoType[] | undefined => {
    const updatedGroups = getGroupsAfterItemIsRecommendedChanged(groups, changeData)
    if (programs === undefined) return programs
    return programs.map(program => {
        const updatedProgramGroups = program.groups.map(group => {
            const recommendedCount = updatedGroups[group.groupId].groupItems.reduce((count, currentItem) => {
                if (currentItem.recommended) return count + 1
                return count
            }, 0)
            return { ...group, groupRecommendedItemsCount: recommendedCount }
        })
        const programHasNoRecommendations = updatedProgramGroups.every(group => {
            return group.groupRecommendedItemsCount === 0
        })
        return { ...program, hasRecommendations: !programHasNoRecommendations  }
    })
}

export const getWheelSectorsAfterItemIsRecommendedChanged = (wheelSectors: WheelSectorWithEvaluationsType[] | undefined, changeData: ChangeItemRecommendationType): WheelSectorWithEvaluationsType[] | undefined => {
    if (wheelSectors === undefined) return wheelSectors
    return wheelSectors.map(sector => {
        let updatedRecommendationsCount = 0
        const items = sector.items.map(item => {
            if (item.itemId === changeData.itemId) {
                if (changeData.recommended) updatedRecommendationsCount += 1
                return { ...item, recommended: changeData.recommended }
            }
            else {
                if (item.recommended) updatedRecommendationsCount += 1
                return item
            }
        })
        return { ...sector, items: items, groupRecommendedItemsCount: updatedRecommendationsCount }
    })
}

export const getBetOrICoreGroupListAfterItemIsRecommendedChanged = (list: GroupWithCountInfoType[] | undefined, groups: GroupDictionaryType,  changeData: ChangeItemRecommendationType): GroupWithCountInfoType[] | undefined => {
    if (list === undefined) return list
    const updatedGroups = getGroupsAfterItemIsRecommendedChanged(groups, changeData)
    return  list.map(group => {
        const recommendationsCount = updatedGroups[group.groupId].groupItems.reduce((count, currentItem) => {
            if (currentItem.recommended) return count + 1
            return count
        }, 0)
        return { ...group, groupRecommendedItemsCount: recommendationsCount }
    })
}









