import { makeStyles, createStyles, Theme } from '@material-ui/core'


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({

        iconButton: {

        },
        icon: {

            color: theme.palette.secondary.main,

            borderRadius: 4,
            padding: 2,

            margin: -8
        },
    })
)
