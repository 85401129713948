import { NoteType } from '../../../types/note/NoteType'



export const changeNoteIsFavorite = (notes: NoteType[] | undefined, changeData: NoteType): NoteType[] | undefined => {
    if (!notes) return undefined
    return notes.map(note => {
        if (note.noteId === changeData.noteId) {
            return changeData
        } else {
            return note
        }
    })
}
