import { GroupDictionaryType } from '../../../types/group/GroupDictionaryType'
import { ItemIsFavoriteChangeType } from '../../../types/item/ItemIsFavoriteChangeType'
import { MarkAsCompletedType } from '../../../types/completion/MarkAsCompletedType'
import { ChangeItemRecommendationType } from '../../../types/recommendation/ChangeItemRecommendationType'



export const getGroupsAfterItemChanged = (
    groups: GroupDictionaryType, 
    favoriteChange: ItemIsFavoriteChangeType | undefined,
    completedChange: MarkAsCompletedType | undefined,
    recommendationChange: ChangeItemRecommendationType | undefined
): GroupDictionaryType => {
    
    const updatedGroups: GroupDictionaryType = {}
    let updateId = -1
    if (favoriteChange !== undefined) {
        updateId = favoriteChange.itemId
    } else if (completedChange !== undefined) {
        updateId = completedChange.itemId
    } else if (recommendationChange !== undefined)  {
        updateId = recommendationChange.itemId
    } else {
        throw new Error('Something must change!')
    }

    Object.values(groups).forEach(group => {
        const groupItems = group.groupItems.map(item => {
            if (item.itemId === updateId && favoriteChange) return { ...item, itemFavoriteId: favoriteChange.favoriteId  }
            if (item.itemId === updateId && completedChange) return { ...item, completed: completedChange.date  }
            if (item.itemId === updateId && recommendationChange) return { ...item, recommended: recommendationChange.recommended  }
            return { ...item }
        })
        updatedGroups[group.groupId] = { ...group, groupItems: groupItems }
    })
    return updatedGroups
}