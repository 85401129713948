import React, { useContext } from 'react'
import Typography from '@material-ui/core/Typography'
import { useStyles } from './styles'
import Logout from '../login-reset/logout/Logout'
import { AppContext } from '../../state/context-initial-state/AppContext'
import HomeIcon from '@material-ui/icons/Home'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'


const Header: React.FC = () => {

    const classes = useStyles()
    const { state } = useContext(AppContext)

    const FILE_NAME = 'mental_logo.jpg'
    const AZURE_BASE = process.env.REACT_APP_AZURE_IMAGES
    const LOGO_URL = AZURE_BASE + FILE_NAME


    return (
        <div className={classes.outerContainer}>
            <div className={classes.rowContainer}>
                {state.loggedInUserData ?
                    <>
                        <div className={classes.leftButtons}>
                            <IconButton component={Link} to='/home' className={classes.iconButton}>
                                <HomeIcon className={classes.icon} fontSize='large'/>
                            </IconButton>
                        </div>
                        <div className={classes.titleLogoRow}>
                            <Avatar alt='image' variant='rounded' src={LOGO_URL} className={classes.avatarBig}/>
                            <div className={classes.titleBox}>
                                <Typography variant='h5' className={classes.headerTitle}>
                                    INNER
                                </Typography>
                                <Typography variant='h5' className={classes.headerTitle}>
                                    CHAMPION
                                </Typography>
                            </div>
                        </div>
                        <Logout/>
                    </>
                    :
                    <div className={classes.login}>
                        <div className={classes.titleLogoRow}>
                            <Avatar alt='image'  variant='rounded' src={LOGO_URL} className={classes.avatarBig}/>
                            <div className={classes.titleBox}>
                                <Typography variant='h5' className={classes.headerTitle}>
                                    INNER
                                </Typography>
                                <Typography variant='h5' className={classes.headerTitle}>
                                    CHAMPION
                                </Typography>
                            </div>
                        </div>
                    </div>
                }
            </div>

        </div>
    )
}


export default Header

