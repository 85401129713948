import * as Yup from 'yup'
import { USERNAME_MIN, USERNAME_COMMENT, PASSWORD_MIN, PASSWORD_COMMENT } from '../../../utils/common-constants/constantValues'


type UserLoginInput = {
    username: string,
    password: string
}


export const getFormInputValidationSchema = (): Yup.SchemaOf<UserLoginInput> => {
    return Yup.object({
        username: Yup.string()
            .min(USERNAME_MIN, USERNAME_COMMENT)
            .required('Required'),
        password: Yup.string()
            .min(PASSWORD_MIN, PASSWORD_COMMENT)
            .required('Required')
    })
}


