
import { AddPersonToTeamActionData } from '../../types/team/AddPersonToTeamActionDataType'
import { CoachAndAdminTeamType } from '../../types/coach-and-admin-teams/CoachAndAdminTeamType'
import { UserRoleType } from '../../types/user/UserRoleType'

export const addNewPersonToTeam = (currentTeams: CoachAndAdminTeamType[] | undefined, newPerson: AddPersonToTeamActionData): CoachAndAdminTeamType[] => {
    if (currentTeams === undefined) return []
    return currentTeams.map(team => {
        if (team.name === newPerson.teamName) {
            if (newPerson.role === UserRoleType.COACH) {
                const teamCoaches = team.coaches ?? []
                return { ...team, coaches: [...teamCoaches, newPerson.username] }
            }
            else 
                return { ...team, players: [...team.players, newPerson.username] }
        } else {
            return team
        }
    })
}