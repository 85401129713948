import { createMuiTheme } from '@material-ui/core/styles'


export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#212121',
            light: '#484848',
            dark: '#000000',
            contrastText: '#f5f5f5'
        },
        secondary: {
            main: '#bdbdbd',
            light: '#efefef',
            dark: '#8d8d8d',
            contrastText: '#0c0c0c'
        },
        error: {
            main: '#a30000',
        },
        success: {
            main: '#4faf11',
        },
        warning: {
            main: '#ff9100',
        },
    }
})