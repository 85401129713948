import React, { lazy, Suspense } from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BrowserRouter as Router, Switch, Route, useRouteMatch } from 'react-router-dom'
import WaitSpinner from '../../common-components/wait-spinner/WaitSpinner'
import Header from '../../header/Header'


const Home = lazy(() => import('../home/Home'))
const AddTeam = lazy(() => import('../add-team/AddTeam'))
const AdminTeams = lazy(() => import('../teams/AdminTeams'))
const AdministrateTeam = lazy(() => import('../../common-components/administrate-team/AdministrateTeam'))
const SendEmailLink = lazy(() => import('../../common-components/administrate-team/SendEmailLink'))


type TeamNameMatchData = {
    teamName: string,
}
type UsernameMatchData = {
    username: string,
}



const AdminApp: React.FC = () => {

    const matchTeamName = useRouteMatch('/administrateTeam/:teamName')
    const teamName = matchTeamName ? (matchTeamName.params as TeamNameMatchData).teamName : undefined
    const matchUsername = useRouteMatch('/sendLink/:username')
    const username = matchUsername ? (matchUsername.params as UsernameMatchData).username : undefined


    return (
        <Suspense fallback={<WaitSpinner/>}>
            <Header/>
            <Switch>
                <Route path='/teams'>
                    <AdminTeams/>
                </Route>
                <Route path='/addTeam'>
                    <AddTeam/>
                </Route>
                <Route path='/administrateTeam/:teamName'>
                    <AdministrateTeam teamName={teamName}/>
                </Route>
                <Route path='/sendLink/:username'>
                    <SendEmailLink username={username}/>
                </Route>
                <Route path='/'>
                    <Home/>
                </Route>
            </Switch>
        </Suspense>
    )
}


export default AdminApp