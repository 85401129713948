import { makeStyles, createStyles, Theme } from '@material-ui/core'


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outerContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        title: {
            marginTop: 5,
            marginBottom: 10,
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            textAlign: 'center',
        },

        listTitle: {
            marginTop: 20,
            marginBottom: 10,
            color: theme.palette.primary.main,
            fontWeight: 'bold',
        },
        username: {
            color: theme.palette.primary.main,
        },
        personContainer: {
            width: '95%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 5,
            marginTop: 5,
        },
        divider: {
            width: '100%',
            height: 1,
            backgroundColor: theme.palette.secondary.main,
        },
        textField: {
            width: '90%',
            backgroundColor: theme.palette.secondary.light,
            marginTop: 20, marginBottom: 20,
        },
        buttonInfo: {
            textAlign: 'center',
            marginTop: 30,
            marginBottom: 10
        },
        infoText: {
            textAlign: 'center',
            marginTop: 5
        },
        warning: {
            textAlign: 'center',
            color: theme.palette.error.main
        }
    })
)

