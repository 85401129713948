import React, { useContext, useState } from 'react'
import { useStyles } from './styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { useSendEmail } from './useSendEmail'
import SnackbarAlert from '../snackbar-alert/SnackbarAlert'
import { SUCCESS_SENDING_RESET_LINK } from '../../../utils/common-constants/constantValues'
import { Severity } from '../../../types/alert/AlertType'
import { AppContext } from '../../../state/context-initial-state/AppContext'
import { Redirect } from 'react-router-dom'


type SendEmailLinkProps = {
    username: string | undefined
}


const SendEmailLink: React.FC<SendEmailLinkProps> = ({ username }) => {

    const classes = useStyles()
    const [email, setEmail] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [redirect, setRedirect] = useState<string | undefined>(undefined)
    const { responseMessage, sendEmail, clearResponseMessage } = useSendEmail()
    const { state } = useContext(AppContext)

    const handleEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }
    const submitLink = async () => {
        if (username) {
            setIsSubmitting(true)
            sendEmail(username, email)
        }
    }
    const isValidEmail = (email: string) => {
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+[.]([a-zA-Z]{2,5})$/
        return regex.test(email)
    }
    const closeAlert = () => {
        setIsSubmitting(false)
        if (responseMessage === SUCCESS_SENDING_RESET_LINK) {
            setEmail('')
            if (state.resetLinkTeam !== undefined) {
                setRedirect(`/administrateTeam/${state.resetLinkTeam}`)
            }
        }
        clearResponseMessage()
    }

    if (redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <>
            <div className={classes.outerContainer}>
                <Typography variant='h6' className={classes.title}>SEND RESET LINK</Typography>
                <Typography className={classes.infoText}>You are about to send a password reset link to:</Typography>
                <Typography variant='h6'><strong>{username}</strong></Typography>
                <Typography className={classes.infoText}>{`Write the email address of "${username}" in the box below:`}</Typography>
                <TextField
                    id='link-email'
                    label={`Email of ${username} here...`}
                    variant='outlined'
                    value={email}
                    onChange={handleEmailChanged}
                    multiline
                    rowsMax={2}
                    className={classes.textField}
                />

                {isValidEmail(email) && !isSubmitting &&
                    <>
                        <Typography className={classes.buttonInfo}>{`Send the password reset link to "${username}" by clicking the button below`}</Typography>
                        <Button
                            id='submit-reset-link'
                            variant='contained'
                            color='primary'
                            onClick={submitLink}
                        >
                            SEND LINK
                        </Button>
                    </>

                }
                {responseMessage !== '' &&
                <SnackbarAlert
                    alert={{
                        isOpen: true,
                        severity: responseMessage === SUCCESS_SENDING_RESET_LINK ? Severity.SUCCESS : Severity.ERROR,
                        onClose: closeAlert,
                        message: responseMessage,
                        autoHideDuration: 5000
                    }}
                />
                }
            </div>
        </>

    )
}


export default SendEmailLink