import { makeStyles } from '@material-ui/core/styles'


export const useStyles = makeStyles((theme) => ({
    loadingText: () => ({
        color: theme.palette.primary.main,
        fontSize: 23,
        marginBottom: 20,
        fontWeight: 'bold'
    }),
    container: () => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 30
    }),
}))