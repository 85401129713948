import { ContextOfAppType } from '../../types/context/ContextOfAppType'
import { createContext } from 'react'
import { initialAppState } from './initialAppState'



export const AppContext = createContext<ContextOfAppType>({
    state: initialAppState,
    dispatch: () => null
})

export default AppContext