import React, { lazy, Suspense } from 'react'
import Header from '../../header/Header'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BrowserRouter as Router, Switch, Route, useRouteMatch } from 'react-router-dom'
import WaitSpinner from '../../common-components/wait-spinner/WaitSpinner'



const ProgramsMainPage = lazy(() => import('../programs/main/ProgramsMainPage'))
const GroupWithItems = lazy(() => import('../group/GroupWithItems'))
const Item = lazy(() => import('../item/main/Item'))
const AddNote = lazy(() => import('../notes/add-new-note/add-note/AddNote'))
const Home = lazy(() => import('../home/Home'))
const WheelView = lazy(() => import('../wheel/wheel-view/WheelView'))
const WheelMaterials = lazy(() => import('../wheel/wheel-materials/WheelMaterials'))
const Sector = lazy(() => import('../wheel/sector/Sector'))
const Notes = lazy(() => import('../notes/notes-list/Notes'))
const BetMainPage = lazy(() => import('../bet/BetMainPage'))
const ICoreMainPage = lazy(() => import('../icore/ICoreMainPage'))
const StatisticsMainPage = lazy(() => import('../statistics/StatisticsMainPage'))
const BasicItem = lazy(() => import('../basic/BasicItem'))
const WheelInfoItem = lazy(() => import('../wheel/info/WheelInfoItem'))
const ChatMainPage = lazy(() => import('../chat/ChatMainPage'))
const Feedback = lazy(() => import('../feedback/Feedback'))
const HowToUseThisApp = lazy(() => import('../how-to/HowToUseThisApp'))


type GroupMatchData = {
    groupId: string,
}
type ItemMatchData = {
    itemId: string,
}
type SectorMatchData = {
    sectorId: string,
}
type BasicItemMatchData = {
    mainTitle: string,
}

const PlayerApp: React.FC = () => {

    const matchProgramGroup = useRouteMatch('/programGroups/:groupId')
    const programGroupId = matchProgramGroup ? (matchProgramGroup.params as GroupMatchData).groupId : undefined
    const matchWheelSectorGroup = useRouteMatch('/wheelGroups/:groupId')
    const wheelSectorGroupId = matchWheelSectorGroup ? (matchWheelSectorGroup.params as GroupMatchData).groupId : undefined
    const matchBetGroup = useRouteMatch('/betGroups/:groupId')
    const betGroupId = matchBetGroup ? (matchBetGroup.params as GroupMatchData).groupId : undefined
    const matchICoreGroup = useRouteMatch('/iCoreGroups/:groupId')
    const iCoreGroupId = matchICoreGroup ? (matchICoreGroup.params as GroupMatchData).groupId : undefined

    const matchItem = useRouteMatch('/items/:itemId')
    const itemId = matchItem ? (matchItem.params as ItemMatchData).itemId : undefined
    const matchSector = useRouteMatch('/sector/:sectorId')
    const sectorId = matchSector ? (matchSector.params as SectorMatchData).sectorId : undefined
    const matchBasicItem = useRouteMatch('/basicitems/:mainTitle')
    const mainTitle = matchBasicItem ? (matchBasicItem.params as BasicItemMatchData).mainTitle : undefined
    const matchInfoItem = useRouteMatch('/wheelinfoitems/:itemId')
    const infoItemId = matchInfoItem ? (matchInfoItem.params as ItemMatchData).itemId : undefined


    return (
        <Suspense fallback={<WaitSpinner/>}>
            <Header/>
            <Switch>
                <Route path='/addNote'>
                    <AddNote/>
                </Route>
                <Route path='/notes'>
                    <Notes/>
                </Route>
                <Route path='/chat'>
                    <ChatMainPage teamName={undefined}/>
                </Route>
                <Route path='/how'>
                    <HowToUseThisApp/>
                </Route>
                <Route path='/wheelview'>
                    <WheelView/>
                </Route>
                <Route path='/wheelmaterials'>
                    <WheelMaterials/>
                </Route>
                <Route path='/sector/:sectorId'>
                    <Sector sectorId={sectorId}/>
                </Route>
                <Route path='/bet'>
                    <BetMainPage/>
                </Route>
                <Route path='/icore'>
                    <ICoreMainPage/>
                </Route>
                <Route path='/programs'>
                    <ProgramsMainPage/>
                </Route>
                <Route path='/programGroups/:groupId'>
                    <GroupWithItems groupId={programGroupId} type='programGroup'/>
                </Route>
                <Route path='/wheelGroups/:groupId'>
                    <GroupWithItems groupId={wheelSectorGroupId} type='wheelGroup'/>
                </Route>
                <Route path='/betGroups/:groupId'>
                    <GroupWithItems groupId={betGroupId} type='betGroup'/>
                </Route>
                <Route path='/iCoreGroups/:groupId'>
                    <GroupWithItems groupId={iCoreGroupId} type='iCoreGroup'/>
                </Route>
                <Route path='/items/:itemId'>
                    <Item itemId={itemId}/>
                </Route>
                <Route path='/basicitems/:mainTitle'>
                    <BasicItem mainTitle={mainTitle}/>
                </Route>
                <Route path='/wheelinfoitems/:itemId'>
                    <WheelInfoItem itemId={infoItemId}/>
                </Route>
                <Route path='/statistics'>
                    <StatisticsMainPage/>
                </Route>
                <Route path='/feedback'>
                    <Feedback/>
                </Route>
                <Route path='/'>
                    <Home/>
                </Route>
            </Switch>
        </Suspense>
    )
}


export default PlayerApp

