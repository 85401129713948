import React, { useContext, useState } from 'react'
import { getFormInputValidationSchema } from './getFormInputValidationSchema'
import { Form, Formik } from 'formik'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import FormInputField from './FormInputField'
import { useStyles } from './styles'
import Typography from '@material-ui/core/Typography'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { handleLoginSubmit } from './handleLoginSubmit'
import WaitSpinner from '../../common-components/wait-spinner/WaitSpinner'
import { LoggedInUserType } from '../../../types/login/LoggedInUserType'
import { AppContext } from '../../../state/context-initial-state/AppContext'
import { ActionTypes } from '../../../types/action/ActionType'
import SnackbarAlert from '../../common-components/snackbar-alert/SnackbarAlert'
import { AlertType } from '../../../types/alert/AlertType'




const LoginForm: React.FC = () => {

    const [passwordIsVisible, setPasswordIsVisible] = useState(false)
    const [submitIssueAlert, setSubmitIssueAlert] = useState<AlertType | null>(null)
    const initialValues = { username: '', password: '' }
    const classes = useStyles()
    const validationSchema = getFormInputValidationSchema()
    const { dispatch } = useContext(AppContext)


    const storeUserDataToAppState = (userData: LoggedInUserType): void => {
        dispatch({ type: ActionTypes.SET_LOGGED_IN_USER, data: userData })
    }


    return (
        <div className={classes.container}>
            <Typography variant='h5' className={classes.formTitle}>LOGIN</Typography>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => handleLoginSubmit(values, setSubmitting, storeUserDataToAppState, setSubmitIssueAlert)}

            >
                {({ isSubmitting }) => {
                    return (
                        <>                        
                            <Form id='formik-form' className={classes.container}>
                                <FormInputField fieldName='username' label='Username' type='text'/>
                                <FormInputField fieldName='password' label='Password' type={passwordIsVisible ? 'text' : 'password'}/>
                                <IconButton onClick={() => setPasswordIsVisible(!passwordIsVisible)}>
                                    {passwordIsVisible ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                                </IconButton>
                                {isSubmitting &&  <WaitSpinner/>}
                                {!isSubmitting && submitIssueAlert === null &&
                                    <Button
                                        id='submit-login-form'
                                        type='submit'
                                        variant='contained'
                                        color='primary'
                                        className={classes.button}
                                    >
                                        SUBMIT
                                    </Button>
                                }
                            </Form>
                            {submitIssueAlert !== null && <SnackbarAlert alert={submitIssueAlert}/>}
                        </>
                    )
                }}
            </Formik>
        </div>
    )
}


export default LoginForm

