import React from 'react'
import { MuiThemeProvider } from '@material-ui/core'
import { theme } from './utils/styles/theme'
import AppContextWithState from './state/context-initial-state/AppContextWithState'
import MentalCoachingApp from './components/main-app/MentalCoachingApp'


function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <AppContextWithState>
                <MentalCoachingApp/>
            </AppContextWithState>
        </MuiThemeProvider>
    )
}

export default App


