import axios from 'axios'
import { getHeaderWithAuthorization } from '../../../utils/common-functions/getHeaderWithAuthorization'
import { UserRoleType } from '../../../types/user/UserRoleType'

const baseUrl = process.env.REACT_APP_SERVER_URL


export const addPersonToTeam = async (token: string, username: string, role: UserRoleType, teamName: string, mustBeNewCoach: boolean | undefined ): Promise<void> => {
    const configurations = getHeaderWithAuthorization(token)
    const requestBody = { username: username, role: role, team: teamName, mustBeNewCoach: mustBeNewCoach }
    await axios.post(`${baseUrl}/persons`, requestBody , configurations)
}

export const sendResetLink = async (token: string, username: string, email: string): Promise<void> => {
    const configurations = getHeaderWithAuthorization(token)
    const requestBody = { username: username, email: email }
    await axios.post(`${baseUrl}/reset/requestLink`, requestBody , configurations)
}
