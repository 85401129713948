import { makeStyles, createStyles, Theme } from '@material-ui/core'


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        outerContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

            backgroundColor: theme.palette.primary.dark,
            borderRadius: 6,
            padding: 4,
            marginBottom: 15,
            position: 'sticky',
            top: 0,
            zIndex: 1000,

        },
        headerTitle: {

            color: theme.palette.secondary.main,

        },
        divider: {

            backgroundColor: theme.palette.primary.dark,
            height: 1.5,
            width: '100%',
        },
        rowContainer: {
            display: 'flex',
            flexDirection: 'row',

            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',

        },
        icon: {

            color: theme.palette.secondary.main,
            borderRadius: 4,
            padding: 2,
            margin: -8
        },
        leftButtons: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',

        },
        iconButton: {

        },
        titleLogoRow: {
            display: 'flex',
            flexDirection: 'row',

            justifyContent: 'space-between',
            alignItems: 'center',
        },
        avatar: {
            marginRight: 15,
        },
        titleBox: {

            marginLeft: -10,
            display: 'flex',
            flexDirection: 'column',

            alignItems: 'flex-start',
        },
        login: {
            display: 'flex',
            flexDirection: 'row',

            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',

        },
        avatarBig: {
            marginRight: 15,
            width: 60,
            height: 60
        },
    })
)
