
import { MarkAsCompletedType } from '../../../types/completion/MarkAsCompletedType'
import { ItemDictionaryType } from '../../../types/item/ItemDictionaryType'



export const getItemsAfterItemMarkedAsCompleted = (items: ItemDictionaryType, changeData: MarkAsCompletedType): ItemDictionaryType => {
    const itemToBeUpdated = items[changeData.itemId]
    
    const updatedItem = { ...itemToBeUpdated, completed: changeData.date }
    items[changeData.itemId] = updatedItem

    return { ...items }
}
//
// export const getGroupsAfterItemMarkedAsCompleted = (groups: GroupDictionaryType, changeData: MarkAsCompletedType): GroupDictionaryType => {
//     const updatedGroups: GroupDictionaryType = {}
//     Object.values(groups).forEach(group => {
//         const groupItems = group.groupItems.map(item => {
//             if (item.itemId === changeData.itemId) return { ...item, completed: changeData.date }
//             return  { ...item }
//         })
//         updatedGroups[group.groupId] = { ...group, groupItems: groupItems }
//     })
//
//     return updatedGroups
// }
