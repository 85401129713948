import React from 'react'
import SyncLoader from 'react-spinners/SyncLoader'
import Typography from '@material-ui/core/Typography'
import { theme } from '../../../utils/styles/theme'
import { useStyles } from './styles'



const WaitSpinner: React.FC = () => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Typography className={classes.loadingText}>
                Please wait a little...
            </Typography>
            <SyncLoader
                color={theme.palette.primary.main}
                loading={true}
            />
        </div>
    )
}




export default WaitSpinner