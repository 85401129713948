// LOGGING IN:
export const USERNAME_MIN = 3
export const USERNAME_COMMENT = `Username must be at least ${USERNAME_MIN} characters long.`
export const PASSWORD_MIN = 8
export const PASSWORD_COMMENT = `Password must be at least ${PASSWORD_MIN} characters long.`
export const LOGIN_FAILED_ALERT = 'Something went wrong. Failed to login.'

// PLAYER ADD NOTE:

export const NOTE_ADDED_SUCCESS = 'New note successfully created!'
export const NOTE_ADDED_FAILURE = 'Failed to create new note!'

// PLAYER ALL NOTES:
export const ERROR_GETTING_NOTES = 'ERROR: Could not get notes!'

// PLAYER PROGRAMS:
export const ERROR_GETTING_USER_PROGRAMS_DATA_MESSAGE = 'ERROR: Could not get programs data!'

// PLAYER CHANGE FAVORITE STATUS:
export const FAVORITE_ERROR_ADD = 'ERROR: failed to add program-display-groups to favorites.'
export const FAVORITE_ERROR_REMOVE = 'ERROR: failed to remove program-display-groups from favorites.'


// MARK ITEM AS COMPLETED:
export const MARK_ITEM_AS_COMPLETED = 'Mark item as completed'
export const ITEM_COMPLETED = 'Item has been completed today'
export const ERROR_MARKING_ITEM_COMPLETED = 'ERROR: Could not mark item as completed!'

// MENTAL WHEEL:
export const SPACING_WHEEL_LOWER_ROW = window.innerWidth * 0.006
export const SPACING_BETWEEN_LOWER_AND_UPPER_ROWS = window.innerWidth * 0.03
export const WHEEL_LABEL_FONT_SIZE = window.innerWidth / 35
export const MAX_LOWER_LABEL_ROW_LETTER_COUNT = 15
export const ERROR_GETTING_WHEEL_SECTOR_DATA_FOR_PLAYER = 'ERROR: Could not get wheel sector data.'
export const ERROR_SETTING_EVALUATION = 'ERROR: Could not save new evaluation data!'

export const ERROR_GETTING_WHEEL_EVALUATION_HISTORY = 'ERROR: Could not get wheel sector evaluation history.'
export const NO_EVALUATION_HISTORY_YET = 'No wheel sector evaluations have been made as yet.'

// ADMINISTRATOR ADD TEAM:
export const TEAM_NAME_MIN_LENGTH = 2
export const ERROR_ADD_NEW_TEAM = 'ERROR: Could not create new team!'
export const SUCCESS_ADD_NEW_TEAM = 'Successfully created new team!'
export const ERROR_TEAM_NAME_ALREADY_IN_USE = 'Team name already in use! Duplicates are not allowed! Come up with another name!'

// ADMINISTRATOR VIEW TEAMS:
export const ERROR_GETTING_ALL_TEAMS = 'ERROR: Could not get all teams.'

// ADMINISTRATOR ADMINISTRATE TEAM:
export const ERROR_ADDING_PERSON_TO_TEAM = 'ERROR: Could not add person to team.'
export const SUCCESS_ADDING_PERSON_TO_TEAM = 'Successfully added new person to team.'
export const ERROR_USERNAME_ALREADY_IN_USE = 'Username already in use! Duplicates are not allowed!'
export const ERROR_SENDING_RESET_LINK = 'ERROR: Could not send reset link.'
export const SUCCESS_SENDING_RESET_LINK = 'Reset link successfully sent.'

// BET GROUPS:
export const ERROR_GETTING_BET_GROUPS = 'ERROR: Could not get BET groups.'

// ICORE ITEMS:
export const ERROR_GETTING_ICORE_ITEMS = 'ERROR: Could not get ICore item.'


// COACH:
export const ERROR_GETTING_COACH_TEAMS = 'ERROR: Could not get teams.'
export const ERROR_GETTING_TEAM_STATISTICS = 'ERROR: Could not get team statistics.'


// FEEDBACK:
export const FEEDBACK_SUCCESS = 'THANK YOU FOR YOUR FEEDBACK!'
export const FEEDBACK_ERROR = 'ERROR: Could not send feedback.'