import React, { useContext } from 'react'
import { ActionTypes } from '../../../types/action/ActionType'
import AppContext from '../../../state/context-initial-state/AppContext'
import ButtonGroupSwitchTriple from '../button-group-switch-triple/ButtonGroupSwitchTriple'


type AllFavoritesRecommendedProps = {
    noFavorites?: boolean
}

const AllFavoritesRecommended: React.FC<AllFavoritesRecommendedProps> = ({ noFavorites }) => {

    const { state, dispatch } = useContext(AppContext)

    const changeShowOnlyFavorites = () => {
        dispatch({ type: ActionTypes.CHANGE_SHOW_ONLY_FAVORITES, data: true })
    }
    const changeShowOnlyRecommended = () => {
        dispatch({ type: ActionTypes.CHANGE_SHOW_ONLY_RECOMMENDED, data: true })
    }
    const changeShowAll = () => {
        dispatch({ type: ActionTypes.CHANGE_SHOW_ONLY_FAVORITES, data: false })
    }
    

    const BUTTON_SWITCH_VALUES = ['ALL', 'FAVORITES', 'RECOMMENDED']
    const displayValue = state.showFavoritesOnly ?
        BUTTON_SWITCH_VALUES[1]
        :
        state.showRecommendedOnly ?
            BUTTON_SWITCH_VALUES[2]
            :
            BUTTON_SWITCH_VALUES[0]

    return (

        <ButtonGroupSwitchTriple
            buttonSwitchValues={BUTTON_SWITCH_VALUES}
            displayValue={displayValue}
            changeShowLeftFunction={changeShowAll}
            changeShowMiddleFunction={changeShowOnlyFavorites}
            changeShowRightFunction={changeShowOnlyRecommended}
            showMiddle={!noFavorites}
        />
    )
}

export default AllFavoritesRecommended
