import { loginUser } from './service'
import { FormikValues } from 'formik'
import { LoggedInUserType } from '../../../types/login/LoggedInUserType'
import { AlertType, Severity } from '../../../types/alert/AlertType'
import { LOGIN_FAILED_ALERT } from '../../../utils/common-constants/constantValues'
import { parseLoginUserData } from '../../../utils/server-response-validation/login/parseLoginUserData'


export const handleLoginSubmit = async (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void,
    storeUserDataToAppState: (userData: LoggedInUserType ) => void,
    setSubmitIssueAlert: (alert: AlertType | null) => void
): Promise<void> => {

    try {
        const loginUserResponseData = await loginUser(values.username, values.password)
        const userData = parseLoginUserData(loginUserResponseData)
        setSubmitting(false)
        storeUserDataToAppState(userData)
    } catch (error) {
        console.log(error)
        const alert = { isOpen: true, severity: Severity.ERROR, onClose: () => setSubmitIssueAlert(null), message: LOGIN_FAILED_ALERT, autoHideDuration: 5000 }
        setSubmitting(false)
        setSubmitIssueAlert(alert)
    }
}







