import React, { useContext } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { useStyles } from './styles'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { AppContext } from '../../../state/context-initial-state/AppContext'
import { ActionTypes } from '../../../types/action/ActionType'
import { useHistory } from 'react-router-dom'

const Logout: React.FC = () => {

    const classes = useStyles()
    const { dispatch } = useContext(AppContext)
    const history = useHistory()
    
    const logout = () => {
        history.push('/')
        dispatch({ type: ActionTypes.LOGOUT, data: null })
    }

    return (
        <IconButton onClick={logout}  className={classes.iconButton}>
            <ExitToAppIcon className={classes.icon} fontSize='large'/>
        </IconButton>
    )
}


export default Logout