import { AppStateType } from '../../../types/app-state/AppStateType'
import { ActionType, ActionTypes } from '../../../types/action/ActionType'
import { assertNever } from '../../../utils/common-functions/assertNever'
import { getLoggedInUser } from '../login/getLoggedInUser'
import { getNotesAfterAddingNewNote } from '../getNotesAfterAddingNewNote'
import { setAllNotes } from '../setAllNotes'
import { setPrograms } from '../program/setPrograms'
import { setProgramGroups } from '../program/setProgramGroups'
import { setItems } from '../item/setItems'
import {
    getItemsAfterItemIsFavoriteChanged,
    getProgramsAfterItemIsFavoriteChanged,
    getWheelSectorsAfterItemIsFavoriteChanged,
    getBetOrICoreGroupListAfterItemIsFavoriteChanged
} from '../item/changeItemIsFavorite'
import { setWheelSectors } from '../wheel/setWheelSectors'
import { getWheelSectorsAfterEvaluation } from '../getWheelSectorsAfterEvaluation'
import { adminSetTeams } from '../adminSetTeams'
import { adminAddNewTeam } from '../adminAddNewTeam'
import { addNewPersonToTeam } from '../adminAddNewPersonToTeam'
import { getItemsAfterItemMarkedAsCompleted } from '../item/markItemAsCompleted'
import { initialAppState } from '../../context-initial-state/initialAppState'
import { setWheelSectorEvaluationHistory } from '../setWheelSectorEvaluationHistory'
import { changeIsReEvaluatingWheel } from '../changeIsReEvaluatingWheel'
import { changeNewWheelEvaluationValues } from '../changeNewWheelEvaluationValues'
import { setWheelSectorGroups } from '../wheel/setWheelSectorGroups'
import { setBetGroups } from '../bet/setBetData'
import { setBetGroupList } from '../bet/setBetGroupList'
import { setICoreGroups } from '../i-core/setICoreData'
import { setICoreGroupList } from '../i-core/setICoreList'
import { setCoachTeams } from '../coach-teams/setCoachTeams'
import {
    getProgramsAfterItemIsRecommendedChanged,
    getBetOrICoreGroupListAfterItemIsRecommendedChanged,
    getItemsAfterItemIsRecommendedChanged,
    getWheelSectorsAfterItemIsRecommendedChanged
} from '../item/changeItemIsRecommended'
import { getGroupsAfterItemChanged } from '../item/getGroupsAfterItemChanged'
import {
    getBetOrICoreGroupListAfterGroupIsRecommendedChanged,
    getGroupsAfterGroupIsRecommendedChanged,
    getProgramsAfterGroupIsRecommendedChanged, getWheelSectorsAfterGroupIsRecommendedChanged
} from '../recommendation/groupRecommendationChanged'
import { changeNoteIsFavorite } from '../note/changeNoteIsFavorite'



export const reducer = (state: AppStateType, action: ActionType): AppStateType => {
    
    switch (action.type) {
    case ActionTypes.SET_LOGGED_IN_USER:
        return { ...state, loggedInUserData: getLoggedInUser(action.data) }
    case ActionTypes.ADD_NEW_NOTE:
        return { ...state, notes: getNotesAfterAddingNewNote(state.notes, action.data) }
    case ActionTypes.SET_ALL_NOTES:
        return { ...state, notes: setAllNotes(action.data) }
    case ActionTypes.SET_PROGRAMS:
        return { ...state, programs: setPrograms(action.data) }
    case ActionTypes.SET_PROGRAM_GROUPS:
        return { ...state, programGroups: setProgramGroups(action.data) }
    case ActionTypes.SET_ITEMS:
        return { ...state, items: setItems(state.items, action.data) }
    case ActionTypes.CHANGE_ITEM_IS_FAVORITE:
        return {
            ...state,
            items: getItemsAfterItemIsFavoriteChanged({ ...state.items }, action.data),
            programGroups: getGroupsAfterItemChanged({ ...state.programGroups }, action.data, undefined, undefined),
            programs: getProgramsAfterItemIsFavoriteChanged(state.programs && [...state.programs], { ...state.programGroups }, action.data),
            wheelSectors: getWheelSectorsAfterItemIsFavoriteChanged(state.wheelSectors && [...state.wheelSectors], action.data),
            wheelSectorGroups: getGroupsAfterItemChanged({ ...state.wheelSectorGroups }, action.data, undefined, undefined),
            betGroups: getGroupsAfterItemChanged({ ...state.betGroups }, action.data, undefined, undefined),
            iCoreGroups: getGroupsAfterItemChanged({ ...state.iCoreGroups }, action.data, undefined, undefined),
            betList: getBetOrICoreGroupListAfterItemIsFavoriteChanged(state.betList, { ...state.betGroups }, action.data),
            iCoreList: getBetOrICoreGroupListAfterItemIsFavoriteChanged(state.iCoreList, { ...state.iCoreGroups }, action.data),
        }
    case ActionTypes.CHANGE_SHOW_ONLY_FAVORITES:
        return {
            ...state,
            showFavoritesOnly: action.data,
            showRecommendedOnly: false
        }
    case ActionTypes.SET_WHEEL_SECTORS:
        return { ...state, wheelSectors: setWheelSectors(action.data) }
    case ActionTypes.SET_WHEEL_SECTOR_GROUPS:
        return { ...state, wheelSectorGroups: setWheelSectorGroups(action.data) }
    case ActionTypes.CHANGE_SECTOR_EVALUATION_DATA:
        return { ...state, wheelSectors: getWheelSectorsAfterEvaluation(state.wheelSectors, action.data) }
    case ActionTypes.ADMIN_SET_TEAMS:
        return { ...state, adminTeams: adminSetTeams(action.data) }
    case ActionTypes.ADMIN_ADD_NEW_TEAM:
        return { ...state, adminTeams: adminAddNewTeam(state.adminTeams, action.data) }
    case ActionTypes.ADMIN_ADD_PERSON_TO_TEAM:
        return { ...state, adminTeams: addNewPersonToTeam(state.adminTeams, action.data) }
    case ActionTypes.COACH_ADD_PERSON_TO_TEAM:
        return { ...state, coachTeams: addNewPersonToTeam(state.coachTeams, action.data) }
    case ActionTypes.MARK_ITEM_AS_COMPLETED:
        return {
            ...state,
            items: getItemsAfterItemMarkedAsCompleted({ ...state.items }, action.data),
            programGroups: getGroupsAfterItemChanged({ ...state.programGroups }, undefined, action.data, undefined),
            wheelSectorGroups: getGroupsAfterItemChanged({ ...state.wheelSectorGroups }, undefined, action.data, undefined),
            betGroups: getGroupsAfterItemChanged({ ...state.betGroups }, undefined, action.data, undefined),
            iCoreGroups: getGroupsAfterItemChanged({ ...state.iCoreGroups }, undefined, action.data, undefined),
        }
    case ActionTypes.LOGOUT:
        return initialAppState
    case ActionTypes.SET_BET_GROUP_DATA:
        return { ...state, betGroups: setBetGroups(action.data) }
    case ActionTypes.SET_LIST_BET_GROUPS:
        return { ...state, betList: setBetGroupList(action.data) }
    case ActionTypes.SET_I_CORE_GROUP_DATA:
        return { ...state, iCoreGroups: setICoreGroups(action.data) }
    case ActionTypes.SET_LIST_I_CORE_GROUPS:
        return { ...state, iCoreList: setICoreGroupList(action.data) }
    case ActionTypes.SET_WHEEL_SECTOR_EVALUATIONS_HISTORY:
        return { ...state, wheelEvaluationsHistory: setWheelSectorEvaluationHistory(action.data) }
    case ActionTypes.CHANGE_IS_RE_EVALUATING_WHEEL:
        return { ...state, isReEvaluatingWheel: changeIsReEvaluatingWheel(action.data) }
    case ActionTypes.CHANGE_WHEEL_NEW_EVALUATI0N_VALUES:
        return { ...state, wheelNewEvaluations: changeNewWheelEvaluationValues(state.wheelNewEvaluations, action.data) }
    case ActionTypes.SET_COACH_TEAMS:
        return { ...state, coachTeams: setCoachTeams(action.data) }
    case ActionTypes.SET_COACH_SELECTED_TEAM:
        return { ...state, coachSelectedTeam: action.data }
    case ActionTypes.CHANGE_ITEM_RECOMMENDED_STATUS:
        return {
            ...state,
            items: getItemsAfterItemIsRecommendedChanged({ ...state.items }, action.data),
            programGroups: getGroupsAfterItemChanged({ ...state.programGroups }, undefined, undefined, action.data),
            wheelSectorGroups: getGroupsAfterItemChanged({ ...state.wheelSectorGroups }, undefined, undefined, action.data),
            betGroups: getGroupsAfterItemChanged({ ...state.betGroups }, undefined, undefined, action.data),
            iCoreGroups: getGroupsAfterItemChanged({ ...state.iCoreGroups }, undefined, undefined, action.data),
            programs: getProgramsAfterItemIsRecommendedChanged(state.programs && [...state.programs], { ...state.programGroups }, action.data),
            wheelSectors: getWheelSectorsAfterItemIsRecommendedChanged(state.wheelSectors && [...state.wheelSectors], action.data),
            betList: getBetOrICoreGroupListAfterItemIsRecommendedChanged(state.betList, { ...state.betGroups }, action.data),
            iCoreList: getBetOrICoreGroupListAfterItemIsRecommendedChanged(state.iCoreList, { ...state.iCoreGroups }, action.data),
        }
    case ActionTypes.CHANGE_SHOW_ONLY_RECOMMENDED:
        return {
            ...state,
            showRecommendedOnly: action.data,
            showFavoritesOnly: false
        }
    case ActionTypes.CHANGE_GROUP_RECOMMENDED_STATUS:
        return {
            ...state,
            programGroups: getGroupsAfterGroupIsRecommendedChanged({ ...state.programGroups }, action.data),
            wheelSectorGroups: getGroupsAfterGroupIsRecommendedChanged({ ...state.wheelSectorGroups },  action.data),
            betGroups: getGroupsAfterGroupIsRecommendedChanged({ ...state.betGroups },  action.data),
            iCoreGroups: getGroupsAfterGroupIsRecommendedChanged({ ...state.iCoreGroups },  action.data),
            programs: getProgramsAfterGroupIsRecommendedChanged(state.programs && [...state.programs], { ...state.programGroups }, action.data),
            wheelSectors: getWheelSectorsAfterGroupIsRecommendedChanged(state.wheelSectors && [...state.wheelSectors], action.data),
            betList: getBetOrICoreGroupListAfterGroupIsRecommendedChanged(state.betList, { ...state.betGroups }, action.data),
            iCoreList: getBetOrICoreGroupListAfterGroupIsRecommendedChanged(state.iCoreList, { ...state.iCoreGroups }, action.data),
        }
    case ActionTypes.SET_MESSAGES:
        return {
            ...state,
            messages: action.data
        }
    case ActionTypes.CHANGE_NOTE_IS_FAVORITE:
        return {
            ...state,
            notes: changeNoteIsFavorite(state.notes, action.data)
        }
    case ActionTypes.SET_RESET_LINK_TEAM:
        return {
            ...state,
            resetLinkTeam: action.data
        }
    case ActionTypes.CHANGE_COACH_IS_RECOMMENDING:
        return {
            ...state,
            coachIsRecommending: action.data
        }
    default:
        return assertNever(action)
    }
    
}


