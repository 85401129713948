import React, { useReducer } from 'react'
import { reducer } from '../reducer/reducer/reducer'
import { initialAppState } from './initialAppState'
import { AppContext } from './AppContext'


const AppContextWithState: React.FC = ({ children }) => {
    
    const [state, dispatch] = useReducer(reducer, initialAppState)
    
    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    )
}

export default AppContextWithState